/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vUnc8YGAC",
    "aws_user_pools_web_client_id": "7jtl0ble6n4oh7s9899p4s2c46",
    "oauth": {}
};


export default awsmobile;
