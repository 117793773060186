import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth } from 'aws-amplify';
import { RouteComponentProps } from 'react-router-dom';


const RoleLandingPage : { [key: string]: any } = {
  MDLA: 'mdlhome',
  AADM: 'admhome',
  PADM: 'pmadmhome',
  PUSR: 'userhome'
}


interface Props extends RouteComponentProps {}

interface UserCredentials {
  username: string,
  password: string
}

const Login: React.FC<Props> = props => {
  const [credentials, setCredentials] = useState<UserCredentials>({username: "", password: ""})

  const login = (username: string, password: string) => {
    alert('logging in' + username + password)
  }

  async function signIn(username: string, password: string) {
      try {
          console.log('amplify signing in')
          const user = await Auth.signIn(username, password);
          if (user) {
            console.log('user', user)
            navigateToRoleLandingPage(user)
          } else { 
            console.log('no user found')
          }
      } catch (error) {
          console.log('error signing in', error);
      }
  }

  async function getUserRole(user: object) {
    let role
    try {
      const attributes = await Auth.userAttributes(user)
      console.log('attributes', attributes)
      let roleAttribute = attributes.find(attributes => {
        return attributes.Name === "custom:role"
      })
      role = roleAttribute ? roleAttribute.Value : null
      console.log('role', role)
    } catch (error) {
      console.log('error getting attributes', error);
    }

    return role
  }

  async function navigateToRoleLandingPage (user:object) {
    try {
      const role = await getUserRole(user)
      let history = props.history
      console.log('url', RoleLandingPage[String(role)])
      history.push(RoleLandingPage[String(role)])
    } catch (error) {
      console.log('error getting role', error);
    }
  }
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    signIn(credentials.username, credentials.password)
  }

  return (
    <div className="loginContainer">
      <h2>LOGIN</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username" className='py-2'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={credentials.username}
            onChange={e => setCredentials({...credentials, username: e.target.value})}
          />
        </Form.Group>
        <Form.Group controlId="password" className='py-2'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={credentials.password}
            onChange={e => setCredentials({...credentials, password: e.target.value})}
          />
        </Form.Group>
        <div className="d-grid pt-4">
        <Button type="submit" disabled={!credentials.username || !credentials.password}>
          Login
        </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
