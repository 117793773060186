import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';

import {
  Login,
  Home,
  NotFound
} from 'views'

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from='/'
        to='/login'
      />
      <Route
        path='/login'
        component={Login}
      />
      <Route
        path='/home'
        component={Home}
      />

      <Route
        path='/mdlhome'
        component={Home}
      />
      <Route
        path='/userhome'
        component={Home}
      />
      <Route component={NotFound}/>
    </Switch>
  );
};

export default Routes;
