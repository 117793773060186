import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Routes from 'routes/Routes';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const App: React.FC = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
