import React from 'react';
import './NotFound.css';

interface Props {}

const NotFound: React.FC<Props> = props => {
  return (
    <div className="NotFound">NotFound</div>
  );
};

export default NotFound;
