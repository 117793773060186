import React from 'react';
import './Home.css';

interface Props {}

const Home: React.FC<Props> = props => {
  return (
    <div className="Home">Home</div>
  );
};

export default Home;
